.form-section {
  height: 876px;
  margin-top: 80px;

  &_container {
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .stars {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .star {
        position: absolute;
        animation: float 4s ease-in-out infinite;
      }

      .star1 {
        top: 15%;
        left: 20%;

        
        @media screen and (max-width: 435px) {
          top: 5%;
        }
      }

      .star2 {
        top: 32%;
        left: 15%;
        animation-delay: 0.5s;

        @media screen and (max-width: 435px) {
          top: 3%;
          left: 45%;
        }
      }

      .star3 {
        top: 40%;
        right: 15%;
        animation-delay: 1s;

        @media screen and (max-width: 435px) {
          top: 5%;
        }
      }

      .star4 {
        top: 33%;
        right: 10%;
        animation-delay: 1.5s;

        @media screen and (max-width: 435px) {
          top: 77%;
        }
      }

      .star5 {
        top: 60%;
        right: 10%;
        animation-delay: 2s;

        @media screen and (max-width: 435px) {
          top: 76%;
          right: 82%;
        }

      }
    }

    &-block {
      width: 640px;
      height: 363px;
      text-align: center;

      @media screen and (max-width: 435px) {
        width: 325px;
      }

      &_title {
        h2 {
          text-transform: uppercase;
          font-size: 40px;
          font-family: "LilitaOne", sans-serif;
        }
      }

      &_subtitle {
        font-size: 16px;
        margin-top: 30px;
      }

      &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 24px;

        &-main {
          position: relative;
          display: flex;
          flex-direction: column;

          .twitter-link {
            position: absolute;
            top: 50%;
            left: 10px; /* Padding for the link */
            transform: translateY(-50%);
            pointer-events: none; /* Make the link unclickable */
          }
        }

        .username-twitter{
          width: 250px;
          border: 2px solid #606060;
          border-radius: 10px;
          padding: 20px 20px 20px 147px; 
          font-family: "Outfit", sans-serif;
          font-weight: 400;

          
          @media screen and (max-width: 435px) {
            width: 175px;
            padding: 20px 20px 20px 110px; 
          }
        }

        input {
          width: 376px;
          border: 2px solid #606060;
          border-radius: 10px;
          padding: 20px;
          font-size: 16px;
          font-family: "Outfit", sans-serif;
          font-weight: 400;

          @media screen and (max-width: 435px) {
            width: 265px;
          }

          &.invalid {
            border-color: red;
          }
        }

        .error-message {
          color: red;
          font-size: 10px;
        }
      }

      &_button {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        // button {
        //   width: 200px;
        //   height: 41px;
        //   padding: 10px 35px;
        //   border-radius: 10px;
        //   background-color: #000;
        //   color: #fff;
        //   font-size: 14px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
      }

      &_alert {
        margin-top: 24px;
      }
    }
  }
}